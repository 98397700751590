import React, { Component } from "react";
import "./index.scss";
import logo from "../../assets/images/logo.png";
import influx_logo from "../../assets/images/influx_logo.svg";
import clockIcon from "./clock.png";
import * as API from "../../configuration/apiconfig";
import { filterPackImageBasedOnImageCode } from "../../utils/FilterImages";
import { formatCurrentTime } from "../../utils/DateTimeFormat";
import queryString from "query-string";
var setTime;

class index extends Component {
  constructor(props) {
    const parsedQueryString = queryString.parse(window.location.search);
    super(props);
    this.state = {
      currentDate: new Date(),
      month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      splashScreenImages: [],
      cinemaId: parsedQueryString.cinemaid != null ? parsedQueryString.cinemaid : null,
      allCinemas: [],
      selectLocation: {}
    };
  }

  componentDidMount() {
    var self = this;
    setTime = setInterval(() => {
      if (self.state.currentDate != new Date()) {
        self.setState({
          currentDate: new Date(),
        });
      }
    }, 2000);
    if (process.env.REACT_APP_SPLASH_SCREEN_SOURCE.includes("https") || process.env.REACT_APP_SPLASH_SCREEN_SOURCE.includes("http")) {
      console.log(process.env.REACT_APP_SPLASH_SCREEN_SOURCE.split(",")[0]);
      this.setState({
        splashScreenImages: process.env.REACT_APP_SPLASH_SCREEN_SOURCE.split(","),
      });
    } else {
      API.callEndpoint("GET", "Bearer", "/cms/v2/packs/" + process.env.REACT_APP_SPLASH_SCREEN_SOURCE).then((response) => {
        var splashScreenImages = filterPackImageBasedOnImageCode(response, process.env.REACT_APP_SPLASH_SCREEN_IMAGECODE);
        this.setState({ splashScreenImages });
      });
    }

    document.cookie = "accessToken=;max-age=0";
    document.cookie = "refreshToken=;max-age=0";
    document.cookie = "firstname=;max-age=0";
    document.cookie = "lastname=;max-age=0";
    localStorage.removeItem("sesstionid");
    localStorage.removeItem("cinemaid");
    localStorage.removeItem("selectedmoviename");
    localStorage.removeItem("selectedSeats");
    if (localStorage.getItem("orderId") != null && localStorage.getItem("orderFlowCompleted") == null) {
      this.deleteOrder();
    } else {
      localStorage.removeItem("orderId");
    }
    localStorage.removeItem("cartData");
    localStorage.removeItem("sessionExpiredRedirectUrl");
    localStorage.removeItem("endTimeInSecs");
    localStorage.removeItem("BookingCountdown");
    localStorage.removeItem("orderFlowCompleted");
    localStorage.getItem("ageRestrictionValidation") != null && localStorage.removeItem("ageRestrictionValidation");
    const urlParams = new URLSearchParams(window.location.search);
    const getCinemaId = urlParams.get("cinemaid");
    // console.log(getCinemaId, 'getCinemaId');
    if (getCinemaId != null) {
      localStorage.setItem("cinemaid", getCinemaId);
    }
    this.getCinemas();
  }
  componentWillUnmount() {
    clearInterval(setTime);
  }

  deleteOrder = () => {
    var orderDetails = JSON.parse(localStorage.getItem("orderId"));
    localStorage.removeItem("orderId");
    if (orderDetails?.id) {
      API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${orderDetails.id}`)
        .then((response) => {
          console.log(response);
        })
        .catch((ex) => {
          console.log(ex.error);
        });
    }
  }

  getCinemas = () => {
    API.callEndpoint("GET", "Bearer", "/cms/v2/cinemas")
      .then((response) => {
        this.setState({
          allCinemas: response.data
        }, () => {
          if (this.state.cinemaId != null) {
            var getObj = this.state.allCinemas.find((x) => x.id == this.state.cinemaId);
            console.log(getObj, 'getObj');
            if (getObj != undefined) {
              this.setState({
                selectLocation: getObj
              })
            }
          }
        })
      })
      .catch((ex) => {
        console.log(ex.error);
      });
  };


  render() {
    var { currentDate, selectLocation } = this.state;
    return (
      <>
        <section className="splash-container">
          <div className="top-sec">
            <div className="timer-sec">
              <img src={clockIcon} className="icon"></img>
              <div className="text-center">
                <h3>{formatCurrentTime(currentDate)}</h3>
                <p>
                  {this.state.days[currentDate.getDay()]}, {this.state.month[currentDate.getMonth()]} {currentDate.getDate()}
                </p>
                {
                  Object.keys(selectLocation).length > 0 ?
                    <p className="location">{selectLocation.name}{selectLocation.city.length > 0 ? ', ' + selectLocation.city[0] : ''}{selectLocation.states.length > 0 ? ', ' + selectLocation.states[0] : ''}</p>
                    : <></>
                }
              </div>
            </div>
          </div>

          <div className="welcome-blk">
            <h1>Welcome <span>Movie Lover</span></h1>
          </div>
          <div id="buttonsContainer">
            <div>
              <button
                type="button"
                className="btn btn-primary"
                id="orderFnBButton"
                onClick={() => {
                  localStorage.setItem("orderType", "fnb");
                  window.location.href = "/login";
                }}
              >
                <span>ORDER&nbsp;</span> FOOD <i>&nbsp;&&nbsp;</i> DRINKS
              </button>
              <button
                onClick={() => {
                  localStorage.setItem("orderType", "ticketing");
                  window.location.href = "/movies";
                }}
                type="button"
                className="btn btn-primary"
                id="getTicketsButton"
              >
                <span>GET&nbsp;</span> MOVIE TICKETS
              </button>
            </div>
          </div>

          <div className="btm-wrap">
            <div className="logo-sec">
              <a href="javascript:;">
                <img src={logo} />
              </a>
            </div>

            <div className="poweredByInflux">
              <p>Powered by</p>
              <img src={influx_logo} className="influxLogo" />
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default index;
